/* 2.1 */
/* Logo
/* ========================================================================== */
.logo {
	width: 220px;
	height: 100px;
	line-height: 100px;
	background-color: var(--bs-primary);
	position: absolute;
	z-index: 999;
	top: 0;
	left: 15px;
	-webkit-transition: none;
	transition: none;
	a {
		margin: auto auto;
		display: block;
		text-align: center;
		-webkit-transition: none;
		transition: none;
	}
	img {
		max-width: 100%;
		height: auto;
		-webkit-transition: none;
		transition: none;
	}
}
.is-sticky {
	.logo {
		width: 140px;
		height: 50px;
		line-height: 50px;
		-webkit-transition: none;
		transition: none;
		img {
			max-width: none;
			height: 40px;
			-webkit-transition: none;
			transition: none;
		}
		a {
			-webkit-transition: none;
			transition: none;
		}
	}
}
.full-screen-map {
	.logo {
		width: 140px;
		height: 50px;
		line-height: 50px;
		img {
			max-width: none;
			height: 40px;
		}
	}
}
@media (max-width: 991px) {
	.logo {
		width: 140px;
		height: 50px;
		line-height: 50px;
		img {
			max-width: none;
			height: 40px;
		}
	}
}
@media (max-width: 480px) {
	.logo {
		padding-top: 0;
		margin-bottom: 30px;
	}
}