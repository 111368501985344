/* 1.3 */
/* Layout
/* ========================================================================== */

html {
	height: 100%;
	min-height: 100%;
}
body {
	min-height: 100%;
}
.content-area {
	position: relative;
	z-index: 0;
}
.widget {
	* {
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.content {
	.widget {
		+ {
			.widget {
				margin-top: 30px;
			}
		}
	}
}
.sidebar {
	.widget {
		+ {
			.widget {
				margin-top: 30px;
			}
		}
	}
}
.footer {
	.widget {
		+ {
			.widget {
				margin-top: 30px;
			}
		}
	}
}
.container.full-width {
	width: 100%;
	max-width: 100%;
	padding-left: 0;
	padding-right: 0;
}
.row {
	margin-top: -30px;
	+ {
		.row {
			margin-top: 0;
		}
	}
	div[class*="col-"] {
		margin-top: 30px;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
	aside[class*="col-"] {
		margin-top: 30px;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
}
.boxed {
	.wrapper {
		max-width: 1200px;
		margin: 0 auto;
		-webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
	}
}

  /* Page section block
  /* ========================================================================== */

  .page-section {
    position: relative;
    padding-top: 70px;
    padding-bottom: 70px;
    overflow: hidden;
	@include media-breakpoint-down(lg) { 
		padding-top: 40px;
    	padding-bottom: 40px;
	 }

	

    &.md-padding {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &.sm-padding {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &.no-padding {
      padding-top: 0;
      padding-bottom: 0;
    }
    &.no-padding-top {
      padding-top: 0;
    }
    &.no-padding-bottom {
      padding-bottom: 0;
    }
    &.no-bottom-space {
      margin-bottom: -70px;
    }
    &.light {
      background-color: #f4f6f7;
    }
    &.dark {
      background-color: #14181c;
      color: #8e9091;
    }
    &.image {
      background: url(../img/page-section-image.png) center top no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }
    &.of-visible {
      overflow: visible;
    }
    &.sub-page {
      padding-top: 30px;
    }
  }
  html {
    position: relative;
    height: 100%;
  }
  body {
    position: relative;
    height: 100%;
  }
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    height: auto;
  }  