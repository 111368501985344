/* Thumbnail featured
/* ========================================================================== */
.thumbnail {
	&.thumbnail-featured {
		background: #e9e9e9;
		.caption {
			padding: 35px 20px;
			&.hovered {
				opacity: 1;
				z-index: 10;
				background-color: #e9e9e9;
				.caption-icon {
					color: #14181c;
				}
			}
		}
		.caption-wrapper {
			padding: 0;
		}
		.caption-inner {
			padding: 0;
		}
		.caption-icon {
			margin-bottom: 20px;
			text-align: center;
			.fa {
				font-size: 90px;
			}
		}
		.caption-title {
			font-size: 18px;
			font-weight: 700;
			line-height: 30px;
			margin-bottom: 5px;
			text-transform: uppercase;
			color: #14181c;
		}
		.caption-text {
			font-size: 15px;
			line-height: 24px;
		}
		.buttons {
			margin-top: 30px;
			.btn {
				font-size: 14px;
				padding: 15px 45px;
			}
		}
		span {
			padding: 0 8px;
			background-color: rgba(0, 0, 0, 0);
		}
		.btn-theme {
			border-width: 1px;
			border-color: #3c3c3c;
			color: #3c3c3c;
		}
	}
}
.thumbnail.thumbnail-featured.hover,
  .thumbnail.thumbnail-featured:hover {
	background: var(--bs-primary);
}
.thumbnail.thumbnail-featured.hover .caption-icon,
  .thumbnail.thumbnail-featured:hover .caption-icon,
  .thumbnail.thumbnail-featured.hover .caption-title,
  .thumbnail.thumbnail-featured:hover .caption-title,
  .thumbnail.thumbnail-featured.hover .caption-text,
  .thumbnail.thumbnail-featured:hover .caption-text {
	color: #ffffff;
}
.thumbnail.thumbnail-featured.hover .btn-theme,
  .thumbnail.thumbnail-featured:hover .btn-theme {
	border-width: 1px;
	border-color: #ffffff;
	color: #ffffff;
}
.thumbnail.thumbnail-featured.hover .caption.hovered,
  .thumbnail.thumbnail-featured:hover .caption.hovered {
	opacity: 0;
}