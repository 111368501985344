/* 5.9 */
/* Recent posts
/* ========================================================================== */

.recent-post {
	.media {
		background-color: #ffffff;
		-moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
		&.pull-left {
			padding-right: 15px;
			padding-bottom: 15px;
		}
		&.pull-right {
			padding-left: 15px;
			padding-bottom: 15px;
		}
	}
	.meta-date {
		padding: 2px 15px 7px 15px;
		font-family: 'Raleway', sans-serif;
		font-weight: 900;
		text-transform: uppercase;
		text-align: center;
		background: #14181c;
		color: #ffffff;
		.day {
			font-size: 34px;
			line-height: 34px;
		}
		.month {
			margin-top: 3px;
			font-size: 18px;
			line-height: 18px;
		}
	}
	.media-body {
		font-size: 16px;
		line-height: 18px;
	}
	.media-category {
		font-size: 16px;
		font-weight: 900;
		line-height: 18px;
		margin-bottom: 7px;
		color: var(--bs-primary);
	}
	.media-heading {
		font-size: 18px;
		font-weight: 900;
		margin-bottom: 25px;
		a {
			color: #14181c;
			&:hover {
				color: var(--bs-primary);
			}
		}
	}
	.media-excerpt {
		font-weight: 100;
		line-height: 24px;
	}
	.media-meta {
		display: block;
		margin-top: 10px;
		font-size: 15px;
		line-height: 18px;
		color: #a5abb7;
		.divider {
			margin: 0 7px 0 5px;
		}
		a {
			color: #a5abb7;
			&:hover {
				color: #14181c;
			}
		}
	}
	.media-link {
		padding: 0;
		margin-right: 30px;
		display: block;
		position: relative;
		overflow: hidden;
		text-align: center;
		
		.fa {
			position: absolute;
			bottom: 50%;
			left: 50%;
			font-size: 24px;
			width: 30px;
			height: 30px;
			line-height: 30px;
			margin-left: -15px;
			margin-bottom: -15px;
			color: #ffffff;
			z-index: 11;
			opacity: 0;
		}
		&:after {
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(230, 0, 0, 0);
			z-index: 10;
		}
		&:hover {
			.fa {
				bottom: 50%;
				opacity: 1;
			}
			&:after {
				background-color: rgba(230, 0, 0, 0.7);
			}
		}
		.badge {
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 11;
			border-radius: 0;
			padding: 5px 10px;
			font-family: 'Raleway', sans-serif;
			text-transform: uppercase;
			font-size: 13px;
			line-height: 13px;
			color: #ffffff;
			.fa {
				position: relative;
				font-size: 13px;
				line-height: 13px;
				width: 13px;
				height: 13px;
				margin: auto;
				left: auto;
				opacity: 1;
			}
			&.type {
				background-color: var(--bs-primary);
			}
			&.post {
				left: auto;
				right: 0;
				background-color: #7f7f7f;
			}
		}
	}
	&.alt {
		margin: 0 auto;
		.media-left {
			padding-right: 30px;
		}
		.media-right {
			padding-left: 30px;
		}
		.media-link {
			margin: 0 0 20px 0;
			.media-object {
				width: 100%;
				max-width: 100%;
			}
		}
		.media-body {
			padding-right: 20px;
			padding-bottom: 20px;
		}
		.media-meta {
			margin-top: 0;
			margin-bottom: 10px;
		}
	}
}
.recent-post .media-meta .fa,
  .recent-post .media-meta .glyphicon {
	font-size: 14px;
	margin-right: 5px;
}
.widget {
	.recent-post {
		.media {
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			display: flex;
			gap: 10px;
			& + .media {
				padding-top: 15px;
				border-top: solid 1px #e9e9e9;
			}
		}
		.media-link {
			margin-right: 10px;
		}
		.media-meta {
			margin-top: 0;
			font-size: 14px;
			color: #14181c;
			a {
				color: #14181c;
				&:hover {
					color: var(--bs-primary);
				}
			}
		}
		.media-heading {
			margin-top: 10px;
			margin-bottom: 0;
			font-size: 15px;
			font-weight: 400;
			line-height: 24px;
		}
	}
}

  @media (max-width: 480px) {
    .recent-post .media-link {     
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
    .recent-post .media-link img {
      width: 100%;
      max-width: 100%;
    }
  }