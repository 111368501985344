/* Car Card
/* ========================================================================== */

.thumbnail-car-card {
background-color: #ffffff;
border-color: #e9e9e9;
-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
&.no-border {
	border:1px solid #e9e9e9 !important;
}
.caption-title {
	font-size: 15px;
	font-weight: 700;
}
.caption-text {
	margin-bottom: 10px;
	color: #7f7f7f;
}
.buttons {
	margin-bottom: 15px;
	.btn-theme {
		padding-left: 40px;
		padding-right: 40px;
		line-height: 1;
		font-weight: 900;
	}
}
.table {
	margin-bottom: 0;
	td {
		font-size: 13px;
		color: #7f7f7f;
		& + td {
			border-left: solid 1px #e9e9e9;
		}
	}
	tbody{
		border-top: 1px solid #ddd;
	}
}
}
.thumbnail-car-card.hover,
.thumbnail-car-card:hover {
border-color: #e9e9e9;
}
.thumbnail-car-card.no-border.hover,
.thumbnail-car-card.no-border:hover {
border: solid 1px #e9e9e9;
}
.car-listing {
.thumbnail-car-card {
	
	.rating {		
		margin-top: 2px;
		margin-left: 5px;
		margin-right: 30px;
		@include media-breakpoint-down(lg) { 
			margin-right: 0;
		}

	}
	.caption-title {
		padding-left: 30px;
		padding-right: 30px;
	}
	.caption-title-sub {
		font-size: 14px;
		font-weight: 700;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 15px;
		color: var(--bs-primary);
	}
	.caption-text {
		font-size: 13px;
		border-top: solid 1px #e9e9e9;
		padding-top: 20px;
		margin-left: 30px;
		margin-right: 30px;
		margin-bottom: 34px;
		// min-height: 80px;
	}
	.table {
		td {
			text-align: center;
			&.buttons {
				padding: 0;
				.btn-theme {
					display: block;
					padding-left: 40px;
					padding-right: 40px;
					padding-top: 13px;
					padding-bottom: 12px;
					line-height: 1;
					font-weight: 900;
					border-color: #14181c;
					background-color: #14181c;
					@include media-breakpoint-down(lg){
						padding: 20px 16px;
					}
					&:hover {
						border-color: var(--bs-primary);
						background-color: var(--bs-primary);
					}
				}
			}
		}
	}
	& + .thumbnail-car-card {
		margin-top: 30px;
	}
}
}
.car-listing .thumbnail-car-card .rating span.star.active:before,
.car-listing .thumbnail-car-card .rating span.star:hover:before,
.car-listing .thumbnail-car-card .rating span.star:hover ~ span.star:before {
color: #ffd200;
}
.car-big-card {
border: solid 1px #e9e9e9;
-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
.row {
	margin-left: 0;
	margin-right: 0;
	[class*="col-"] {
		padding-left: 0;
		padding-right: 0;
	}
}
.car-details {
	.title {
		padding-left: 0;
		h2 {
			font-size: 14px;
			line-height: 18px;
			font-weight: 700;
			text-transform: uppercase;
			margin-top: 1px;
			margin-bottom: 7px;
			color: #14181c;
			span {
				color: var(--bs-primary);
			}
		}
		&:before {
			display: none;
		}
	}
	.price {
		padding: 15px 30px;
		background-color: #e9e9e9;
		color: #14181c;
		strong {
			font-family: 'Raleway', sans-serif;
			font-size: 36px;
		}
		i {
			color: var(--bs-primary);
		}
	}
	.list {
		padding: 10px 5px 0 30px;
	}
	.button {
		padding: 0 30px;
		margin-bottom: 15px;
	}
	ul {
		margin-bottom: 15px;
		li {
			font-size: 14px;
			border-bottom: dashed 1px #d3d3d3;
			padding: 8px 0 8px 20px;
			position: relative;
			&:before {
				content: '\f058';
				font-family: 'FontAwesome';
				position: absolute;
				top: 7px;
				left: 0;
				color: var(--bs-primary);
			}
		}
	}
}
.btn-zoom {
	position: absolute;
	padding: 0;
	line-height: 30px;
	width: 30px;
	height: 30px;
	right: 10px;
	top: 10px;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.3);
	color: #ffffff;
	&:hover {
		background-color: rgba(0, 0, 0, 0.8);
	}
	.fa {
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
}
.car-thumbnails {
	position: absolute;
	left: 0;
	bottom: 10px;
	z-index: 1;
	a {
		display: block;
		position: relative;
		border: solid 4px rgba(255, 255, 255, 0.6);
		margin: 0 0 10px 10px;
		&:hover {
			border-color: rgba(var(--bs-primary-rgb), 0.6);
			background-color: rgba(var(--bs-primary-rgb), 0.6);
			&:after {
				content: '\f002';
				font-family: 'FontAwesome';
				display: block;
				position: absolute;
				width: 40px;
				height: 40px;
				line-height: 40px;
				margin: -20px 0 0 -20px;
				text-align: center;
				left: 50%;
				top: 50%;
				color: #ffffff;
			}
			img {
				opacity: 0.8;
			}
		}
	}
	.swiper-pagination-bullet {
		width: inherit;
		height: inherit;
		border: none !important;
		background-color: transparent !important;
		&.swiper-pagination-bullet-active {
			a {
				border-color: var(--bs-primary) !important;
			}
		}
	}
}
.owl-dots {
	display: none !important;
}
&.alt {
	.car-details {
		.price {
			padding-top: 5px;
			background-color: #f5f6f8;
		}
		ul {
			li {
				&:last-child {
					border: none;
				}
			}
		}
	}
}
}



@media (max-width: 480px) {
.car-big-card .car-thumbnails .swiper-pagination-bullet {
	width: 30px;
}
.car-big-card .car-thumbnails .swiper-pagination-bullet a {
	border-width: 1px;
}
.car-big-card .car-thumbnails .swiper-pagination-bullet img {
	width: 100%;
	max-width: 100%;
}
}

@media (max-width: 991px) {
.car-big-card .row > .col-md-4 {
	margin-top: 0;
}
}