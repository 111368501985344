/* -------------------------------------------------------------------------- */
/* 1.1 */
/* Preloader
/* ========================================================================== */

@-webkit-keyframes stretchdelay {
	0%,40%,100% {
		-webkit-transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
	}
}
@keyframes stretchdelay {
	0%,40%,100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}
#preloader {
	position: fixed;
	z-index: 999999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #ffffff;
}
#preloader-status {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200px;
	height: 200px;
	margin: -100px 0 0 -100px;
}
#preloader-title {
	font-size: 10px;
	text-align: center;
	text-transform: uppercase;
}
.spinner {
	margin: 0 auto;
	width: 120px;
	height: 30px;
	text-align: center;
	font-size: 10px;
	>div {
		background-color: var(--bs-primary);
		height: 100%;
		width: 20px;
		display: inline-block;
		-webkit-animation: stretchdelay 1.2s infinite ease-in-out;
		animation: stretchdelay 1.2s infinite ease-in-out;
	}
	.rect2 {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}
	.rect3 {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
	}
	.rect4 {
		-webkit-animation-delay: -0.9s;
		animation-delay: -0.9s;
	}
	.rect5 {
		-webkit-animation-delay: -0.8s;
		animation-delay: -0.8s;
	}
}