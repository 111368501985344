/* 5.4 */
/* Social icons line
/* ========================================================================= */

.social-icons {
	margin: -15px 0 20px 0;
	line-height: 30px;
	overflow: hidden;
	li {
		padding: 15px 10px 0 0;
		display: inline-block;
	}
	a {
		display: block;
		position: relative;
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		background-color: transparent;
		border: solid 1px #14181c;
		color: #14181c;
		&:hover {
			background-color: #4d0000;
			color: #ffffff;
		}
	}
}

.map-marker-icon{
	font-size: 60px;
}
