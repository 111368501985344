/* 5.1 */
/* Breadcrumbs
/* ========================================================================== */
.page-section {
	&.breadcrumbs {
		background-color: #f5f6f8;
		padding-top: 0;
		padding-bottom: 0;
		z-index: 1;
		.breadcrumb {
			position: relative;
			padding: 5px 0 0 0;
			margin-bottom: 10px;
			font-size: 12px;
			font-weight: 700;
			line-height: 14px;
			text-transform: uppercase;
			background-color: transparent;
			a {
				color: #9ea6b1;
				&:hover {
					border-bottom: solid 1px #14181c;
				}
			}
			li {
				margin-bottom: 10px;
			}
			& > li {
				& + li {
					&:before {
						content: '\f105 ';
						font-family: 'FontAwesome';
					}
				}
			}
			& > .active {
				color: #a5abb7;
			}
		}
		.page-header {
			padding: 0;
			margin: 25px 0 0 0;
			border: none;
			h1 {
				margin: 0;
				padding: 0;
				font-size: 70px;
				font-weight: 700;
				@include media-breakpoint-down(lg) { 
					font-size: 35px;
				}
			}
		}
	}
}
.page-section.breadcrumbs .page-header h1,
  .page-section.breadcrumbs .page-header h1 small {
	color: #9ea6b1;
	text-transform: uppercase;
}
