/* 5.16 */
/* Back to top button
/* ========================================================================== */
.to-top {
	z-index: 9999;
	width: 40px;
	height: 40px;
	font-size: 25px;
	line-height: 35px;
	text-align: center;
	position: fixed;
	bottom: -100px;
	right: 20px;
	margin-left: -20px;
	cursor: pointer;
	overflow: hidden;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	background-color: transparent;
	background-color: rgba(var(--bs-primary-rgb), 0.5);
	border: solid 2px var(--bs-primary);
	color: #ffffff;
	&:hover {
		background-color: #ffffff;
		border-color: var(--bs-primary);
		color: var(--bs-primary);
	}
}