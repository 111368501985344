/* 1.6 */
/* Form / Input / Textarea / Select
/* ========================================================================== */

@media (min-width: 992px) {
	.slide1 .row-inputs .container-fluid{
		display: flex;
		align-items: end;		
	}
	
}
.slide1, .slide2 , .slide3{
	.form-control-icon {			
		top: 38%;
		right: 2%;			
	}
}

.form-control {
	-webkit-appearance: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: 50px;
	border-radius: 0;
	border: 1px solid #e9e9e9;
	color: #14181c;
	&:focus {
		-webkit-appearance: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		border-color: #14181c;
	}
}
.has-icon {
	.form-control {
		padding-right: 50px;
	}
	.bootstrap-select.btn-group {
		.btn {
			.caret {
				display: none;
			}
		}
	}
}
.dark {
	.form-control {
		background: rgba(255, 255, 255, 0.2);
		border: 1px solid rgba(255, 255, 255, 0);
		color: rgba(255, 255, 255, 0.6);
		&:focus {
			background: rgba(255, 255, 255, 0.1);
			border: 1px solid rgba(255, 255, 255, 0.2);
		}
	}
	.bootstrap-select {
		.dropdown-menu {
			.form-control {
				background: rgba(20, 24, 28, 0.2);
				border: 1px solid rgba(20, 24, 28, 0);
				color: rgba(20, 24, 28, 0.6);
				&:focus {
					background: rgba(20, 24, 28, 0.1);
					border: 1px solid rgba(20, 24, 28, 0.2);
				}
			}
		}
	}
	.form-control-icon {
		color: rgba(255, 255, 255, 0.6);
	}
	.alt {
		.form-control-icon {
			color: #ffffff;
		}
	}
	label {
		color: #ffffff;
	}
}
.light {
	.form-control {
		background: #ffffff;
		border: 1px solid #d3d3d3;
		color: #a5abb7;
		&:focus {
			border: 1px solid #a5abb7;
		}
	}
	.form-control-icon {
		color: rgba(20, 24, 28, 0.6);
	}
	label {
		color: #14181c;
		font-weight: 700;
	}
}
.form-control.alt {
	border-color: #f5f6f8;
	background-color: #f5f6f8;
	color: #6d7582;
	&::-webkit-input-placeholder {
		color: #6d7582 !important;
		font-weight: 400 !important;
		font-size: 14px;
		text-transform: none;
		line-height: 22px;
	}
	&:-moz-placeholder {
		color: #6d7582 !important;
		font-weight: 400 !important;
		font-size: 14px;
		text-transform: none;
		line-height: 22px;
	}
	&::-moz-placeholder {
		color: #6d7582 !important;
		font-weight: 400 !important;
		font-size: 14px;
		text-transform: none;
		line-height: 22px;
	}
	&:-ms-input-placeholder {
		color: #6d7582 !important;
		font-weight: 400 !important;
		font-size: 14px;
		text-transform: none;
		line-height: 22px;
	}
	&:focus {
		-webkit-appearance: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		border-color: #14181c;
	}
}
.form-control-icon {
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	&.clock{
		top: 38%;
	}
}
.has-label {
	.form-control-icon {
		top: 28px;
	}
}
.form-group.has-icon {
	position: relative;
	.sr-only{
		// visibility: hidden;
		display: none;
	}
}
textarea {
	resize: none;
}
.bootstrap-select {
	.dropdown-menu {
		border-radius: 0;
		a {
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
	>.selectpicker {
		-webkit-appearance: none;
		-webkit-box-shadow: none;
		box-shadow: none !important;
		height: 40px;
		border-radius: 0;
		background-color: transparent !important;
		color: #5c5c5c !important;
		&:focus {
			border-color: #14181c;
		}
	}
}
.bootstrap-select-searchbox {
	.form-control {
		border-radius: 0;
	}
}
.alert {
	border-radius: 0;
}
label {
	font-size: 14px;
	color: #14181c;
}
input[type=checkbox] {
	margin-top: 2px;
	margin-right: 5px;
}
.form-login {
	.row {
		margin-top: -20px;
		+ {
			.row {
				margin-top: 0;
			}
		}
		div[class*="col-"] {
			margin-top: 20px;
		}
		aside[class*="col-"] {
			margin-top: 20px;
		}
	}
	.hello-text-wrap {
		line-height: 12px;
	}
	.btn-block {
		margin-left: auto;
		margin-right: auto;
	}
	.btn.facebook {
		background-color: #3e5c98;
		border-color: #3e5c98;
		color: #ffffff;
		&:hover {
			background-color: #14181c;
			border-color: #14181c;
		}
	}
	.btn.twitter {
		background-color: #22a9e0;
		border-color: #22a9e0;
		color: #ffffff;
		&:hover {
			background-color: #14181c;
			border-color: #14181c;
		}
	}
	.btn-theme {
		padding-top: 14px;
		padding-bottom: 14px;
	}
	.form-group {
		margin-bottom: 0;
	}
	.form-control {
		height: 50px;
	}
	.checkbox {
		font-size: 11px;
		margin-top: 0;
		margin-bottom: 0;
		text-transform: uppercase;
		color: #14181c;
		label {
			font-weight: 700 !important;
		}
		input[type=checkbox] {
			margin-top: 2px;
		}
	}
	.col-md-12.col-lg-6.text-right-lg {
		line-height: 0;
	}
	.forgot-password {
		color: #14181c !important;
		font-weight: 700 !important;
		font-size: 11px;
		text-transform: uppercase;
		line-height: 18px;
		&:hover {
			border-bottom: solid 1px #14181c;
		}
	}
	&::-webkit-input-placeholder {
		color: #14181c !important;
		font-weight: 700 !important;
		font-size: 11px;
		text-transform: uppercase;
		line-height: 22px;
	}
	&:-moz-placeholder {
		color: #14181c !important;
		font-weight: 700 !important;
		font-size: 11px;
		text-transform: uppercase;
		line-height: 22px;
	}
	&::-moz-placeholder {
		color: #14181c !important;
		font-weight: 700 !important;
		font-size: 11px;
		text-transform: uppercase;
		line-height: 22px;
	}
	&:-ms-input-placeholder {
		color: #14181c !important;
		font-weight: 700 !important;
		font-size: 11px;
		text-transform: uppercase;
		line-height: 22px;
	}
}
.create-account {
	color: #14181c;
	.list-check {
		font-weight: 700;
	}
	.row {
		margin-top: -20px;
		+ {
			.row {
				margin-top: 0;
			}
		}
		div[class*="col-"] {
			margin-top: 20px;
		}
		aside[class*="col-"] {
			margin-top: 20px;
		}
	}
	.hello-text-wrap {
		line-height: 12px;
	}
	.btn-theme {
		padding-top: 14px;
		padding-bottom: 14px;
	}
	.btn-create {
		margin-top: 15px;
	}
}
.page-section.subscribe {
	background-attachment: scroll !important;
}
.form-subscribe {
	position: relative;
	.form-control {
		border-color: #14181c;
	}
	.btn-submit {
		position: absolute;
		top: 24px;
		right: 0;
		font-size: 12px;
		line-height: 12px;
		padding: 18px 50px;
	}
}
.page-section.find-car {
	overflow: visible;
	padding-top: 24px;
	padding-bottom: 24px;
}
.form-find-car {
	.section-title {
		margin-top: 4px;
	}
	.form-group {
		margin: 0;
	}
	.form-control {
		height: 40px;
	}
	.has-icon {
		.form-control {
			padding-right: 40px;
		}
	}
	.form-control-icon {
		width: 40px;
		height: 40px;
		line-height: 40px;
	}
	.btn-submit {
		margin-top: 24px;
		font-size: 12px;
		line-height: 12px;
		padding: 13px 20px;
	}
}
@media (min-width: 992px) {
	.form-login {
		.btn-block {
			max-width: 100%;
		}
	}
}
@media (min-width: 1200px) {
	.form-login {
		.col-md-12.col-lg-6.text-right-lg {
			margin-bottom: 2px;
		}
	}
}
@media (max-width: 1199px) {
	.form-login {
		.col-md-12.col-lg-6.text-right-lg {
			margin-top: 0;
		}
	}
}
.form-select{
	border-radius: 0;
	&:focus{
		border-color: #ced4da;
		outline: 0;
		box-shadow: none;
	}
}