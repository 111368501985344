/* 5.6 */
/* Tabs
/* ========================================================================== */

.content-tabs {
	.nav-tabs {
		border-bottom: none;
		& > li {
			  a {
				margin-top: 5px;
				padding: 10px 35px;
				background-color: #f5f6f7;
				color: #14181c;

				&.active{
					position: relative;
					margin-top: 0;
					padding-bottom: 10px;
					padding-top: 10px;
					background-color: #fff !important;					
					border-right: 1px solid #e9e9e9;
					border-left: 1px solid #e9e9e9;
					border-bottom: none;
					border-top: solid 1px #14181c;
					color: var(--bs-primary);
				}
				&:before {
					content: '';
					display: block;
					height: 4px;
					width: 100%;
					position: absolute;
					top: -4px;
					left: -1px;
					background-color: #14181c;
				}
				&:after {
					content: '';
					display: block;
					height: 4px;
					width: 100%;
					position: absolute;
					top: -4px;
					right: -1px;
					background-color: #14181c;
				}
			}	 		
		}
	}
	.tab-content {
		padding: 15px;
		border: solid 1px #e9e9e9;
		background-color: #ffffff;
		margin-top: 6px;
	}
	.media {
		& > .pull-left {
			margin-right: 15px;
		}
		& > .pull-right {
			margin-left: 15px;
		}
		& + .media {
			border-top: solid 1px #e9e9e9;
			padding-top: 15px;
		}
		p {
			margin-bottom: 0;
			font-size: 13px;
			& + p {
				margin-top: 10px;
			}
		}
		.media-heading {
			margin-bottom: 0;
			font-size: 14px;
			font-weight: bold;
			line-height: 20px;
		}
		.post-date {
			margin-bottom: 0;
			font-size: 12px;
			color: #d3d3d3;
			line-height: 20px;
		}
	}
	#item-description {
		p {
			margin-bottom: 0;
			& + p {
				margin-top: 20px;
			}
		}
	}
	.rating {
		display: block;
		margin-bottom: 30px;
	}
}
.content-tabs .nav-tabs > li > a,
  .content-tabs .nav-tabs > li > a:focus {
	border-radius: 0;
	border-color: #e9e9e9 #e9e9e9 #e9e9e9;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	background-color: #f5f6f7;
	
}


  @media (min-width: 1200px) {
    .content-tabs .nav-tabs > li > a {
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  @media (min-width: 1200px) {
    .content-tabs .tab-content {
      padding: 35px;
    }
  }