/* --------------------------------------------------------------------------
 * jThemes Studio : RENT IT - Car Rental Management Template
 *
 * file           : theme.css
 * Desc           : RENT IT Template - Stylesheet
 * Version        : 1.3.1
 * Date           : 2016-04-01
 * Author         : jThemes Studio
 * Author URI     : http://jakjim.com
 * Email          : jakjim@gmail.com
 *
 * jThemes Studio. Copyright 2015. All Rights Reserved.
 * -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------
 *  RENT IT Template - Table of Content

  1 - General
    1.1 - Preloader
    1.2 - Global properties (body, common classes, structure etc)
    1.3 - Page section block
    1.4 - Typography (section title, links, page dividers)
    1.5 - Buttons
    1.6 - Form / Input / Textarea
    1.7 - Top Bar

  2 - Header
    2.1 - Logo
    2.2 - Navigation

  3 - Footer
    3.1 - Footer widgets
    3.2 - Footer meta

  4 - Sliders / OwlCarousel
    4.1 - Main slider
    4.2 - Partners carousel
    4.3 - Testimonials carousel
    4.4 - Products carousel

  5 - Components / Shortcodes / Widget
    5.1 - Breadcrumbs
    5.2 - Pagination
    5.3 - Message box
    5.4 - Social icons line
    5.5 - Rating stars
    5.6 - Content tabs
    5.7 - Accordion
    5.8 - Blog post
    5.9 - Recent post
    5.10 - Comments
    5.11 - Thumbnails
    5.12 - Portfolio
    5.13 - Widgets
    5.14 - Contacts form
    5.15 - Google map
    5.16 - Back to top button

  6 - Helper Classes

 * -------------------------------------------------------------------------- */
@import "custom";

@import "bootstrap/scss/bootstrap";
@import "01-general/buttons";
@import "01-general/forms";
@import "01-general/global-properties";
@import "01-general/page-layouts";
@import "01-general/preloader";
@import "01-general/typography";
@import "02-header/logos";
@import "02-header/navigations";
@import "03-footer/footers";
@import "04-sliders/main-slider";
@import "04-sliders/partners-carousel";
@import "04-sliders/product-carousel";
@import "04-sliders/testimonial-carousel";
@import "04-sliders/car-carousel";
@import "04-sliders/image-carousel";
@import "05-components/accordions";
@import "05-components/back-to-top";
@import "05-components/blogs";
@import "05-components/breadcrumbs";
@import "05-components/car-card";
@import "05-components/comments";
@import "05-components/contact-forms";
@import "05-components/content-tabs";
@import "05-components/google-maps";
@import "05-components/headers";
@import "05-components/helper-classes";
@import "05-components/message-box";
@import "05-components/pagination";
@import "05-components/portfolio";
@import "05-components/rating-stars";
@import "05-components/recent-posts";
@import "05-components/shops";
@import "05-components/social-icons";
@import "05-components/tabs";
@import "05-components/thumbnail-banner";
@import "05-components/thumbnail-counto";
@import "05-components/thumbnail-features";
@import "05-components/thumbnail-products";
@import "05-components/thumbnail";
@import "05-components/widget";

