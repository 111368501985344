/* 5.7 */
/* Accordions / FAQ
/* ========================================================================== */
.accordion {
	--bs-accordion-btn-padding-x: 0rem;
	--bs-accordion-btn-padding-y: .6rem;
	margin-bottom: 30px;

	&.payments-options{
		.dot{
			margin-top: 0;
		}
		.accordion-item:not(:first-of-type) {
			border-top: none;
		}
		.panel-heading {			
			border-bottom: none !important;			
		}
	}
	.panel {
		border-radius: 0 !important;
		-webkit-box-shadow: none;
		box-shadow: none;
		border-bottom: solid 1px #e9e9e9;
	}
	.panel-heading {
		background: none;
		padding-left: 20px;
		padding-right: 0;
		border-bottom: solid 1px #e9e9e9;
		margin-bottom: -1px;
		position: relative;
	}
	.panel-title {
		font-size: 16px;
		line-height: 22px;
		color: #14181c;
		.collapsed {
			font-weight: 400;
		}
		a {
			display: block;
			color: #14181c;
			&:hover {
				color: var(--bs-primary);
			}
		}
		.overflowed {
			margin-top: -2px;
			img {
				margin-left: 5px;
			}
		}
	}
	.panel-body {
		padding-top: 20px;
		padding-right: 40px;
		padding-left: 20px;
		font-size: 15px;
	}
	.panel-default {
		& > .panel-heading {
			& + .panel-collapse {
				& > .panel-body {
					border: none;
				}
			}
		}
	}
	.dot {
		display: inline-block;		
		width: 42px;
		height: 42px;
		line-height: 42px;
		margin-top: -10px;
		margin-bottom: -10px;
		text-align: center;
		position: absolute;
		right: 0;		
		background: #e9e9e9;
		color: #7f7f7f;
		border-left: solid 1px #d3d3d3;
		&:before {
			content: '\f068';
			font-family: 'FontAwesome';
		}
	}
	.collapsed {
		.dot {
			&:before {
				content: '\f067';
			}
		}
	}
	.alert {
		margin-bottom: 0;
		color: #14181c;
	}	
	.accordion-button:not(.collapsed) {		
		background-color: transparent;
		box-shadow: none;
	}
	.accordion-button:focus {		
		border-color: none;
		outline: 0;
		box-shadow: none;
	}
	.accordion-item:not(:first-of-type) {
		border-top: 1px solid #e9e9e9;
	}
}